import React, { Component } from 'react';
import styled from 'styled-components';
import {
  BackgroundOverlay,
  Layout,
  RenderWhenReady,
  SEO,
  Separator,
  ResponsiveVideo,
} from 'components';
import { isMobile } from 'react-device-detect';
import { graphql } from 'gatsby';
import { mq } from 'sharedStyles';
import Img from 'gatsby-image';
import Tile from 'images/shared/tile.png';

export default class Athletes extends Component {
  state = {
    activeProfile: 1,
    playingVimeoVideoCode: 445267774,
    playVideo: false,
  };

  handleProfileClick = (clickedProfile) => {
    const playingVimeoVideoCode =
      clickedProfile === 1
        ? 445267774
        : clickedProfile === 2
        ? 445286395
        : clickedProfile === 3
        ? 445276720
        : 445267774;

    this.setState({
      activeProfile: clickedProfile,
      playingVimeoVideoCode,
      playVideo: false,
    });
  };

  handleVideoButtonClick = () => {
    console.log('called');
    this.setState({
      playVideo: true
    });
  };

  render() {
    const {
      roisin,
      fiona,
      emma,
      hockeyIrelandLogo,
      irfuLogo,
      specialOlympicsLogo,
      clickToWatch,
    } = this.props.data;
    const { activeProfile, playingVimeoVideoCode, playVideo } = this.state;
    return (
      <Layout>
        <RenderWhenReady>
          <SEO
            pageSpecificTitle="Athletes"
            pageSpecificDescription="Learn more about some of Ireland's most successful athletes."
            keywords={[
              'sport ireland',
              'love sport',
              'women in sport',
              'Roisin Upton',
              'Róisín Upton',
              'Fiona Coghlan',
              'Emma Johnstone',
              'olympics',
              'special olympics',
            ]}
          />
          <Separator>
            <NameWrapper>
              <NameH3 active={activeProfile === 1} onClick={() => this.handleProfileClick(1)}>
                Roisin
              </NameH3>
              <NameH3 active={activeProfile === 2} onClick={() => this.handleProfileClick(2)}>
                Fiona
              </NameH3>
              <NameH3 active={activeProfile === 3} onClick={() => this.handleProfileClick(3)}>
                Emma
              </NameH3>
            </NameWrapper>
          </Separator>
          <AthleteProfile>
            <BackgroundOverlay />
            {activeProfile === 1 && (
              <DataWrapper>
                <h1>Love Sport</h1>
                <h1>Hockey Ambassador</h1>
                <hr />
                <h3>Roisin Upton</h3>
                <FieldAndValue>
                  <Field>Name:</Field>
                  <Value>Róisín Upton</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Position:</Field>
                  <Value>CM/CB</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Birthplace:</Field>
                  <Value>Limerick</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>School:</Field>
                  <Value>Crescent College Comprehensive</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>College:</Field>
                  <Value>Mary Immaculate College</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Club:</Field>
                  <Value>Catholic Institute</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Career High:</Field>
                  <Value>World Cup Silver Medal 2018</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Age started playing:</Field>
                  <Value>12</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Sporting Career Goal:</Field>
                  <Value>Medal at the Olympics</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Favourite Quote:</Field>
                  <Value>A smooth sea never made a skilled sailor.</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Advice:</Field>
                  <Value>Set goals. Keep learning. Enjoy yourself.</Value>
                </FieldAndValue>
                <hr />
                <AssociationWrapper>
                  <AssociationLink href="http://www.hockey.ie/" target="__blank">
                    <h4>Click here for more info about Hockey</h4>
                  </AssociationLink>
                  <AssociationLink href="http://www.hockey.ie/" target="__blank">
                    <HockeyIrelandLogo
                      style={{ position: 'absolute' }}
                      fluid={hockeyIrelandLogo.childImageSharp.fluid}
                    />
                  </AssociationLink>
                </AssociationWrapper>
              </DataWrapper>
            )}
            {activeProfile === 2 && (
              <DataWrapper>
                <h1>Love Sport</h1>
                <h1>Rugby Ambassador</h1>
                <hr />
                <h3>Fiona Coghlan</h3>
                <FieldAndValue>
                  <Field>Name:</Field>
                  <Value>Fiona Coghlan</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Position:</Field>
                  <Value>Loosehead Prop</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Birthplace:</Field>
                  <Value>Dublin</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>School:</Field>
                  <Value>Holy Faith Clontarf</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Club:</Field>
                  <Value>UL Bohemians RFC</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Career High:</Field>
                  <Value>
                    So many, but winning the Grand Slam with a great squad and management.
                  </Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Age started playing:</Field>
                  <Value>When I went to college at 18 at University of Limerick.</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Sporting Career Goal:</Field>
                  <Value>
                    Nowadays, to stay fit and healthy. When I was competing, to be the best I could
                    be.
                  </Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Favourite Quote:</Field>
                  <Value>Hard work beats talent when talent doesn&apos;t work.</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Advice:</Field>
                  <Value>
                    Search & find something you are interested in & give it a try. Keep searching
                    until you find the sport you enjoy the most.
                  </Value>
                </FieldAndValue>
                <hr />
                <AssociationWrapper>
                  <AssociationLink href="https://www.irishrugby.ie/" target="__blank">
                    <h4>Click here for more info about Rugby</h4>
                  </AssociationLink>
                  <AssociationLink href="https://www.irishrugby.ie/" target="__blank">
                    <IRFULogo
                      style={{ position: 'absolute' }}
                      fluid={irfuLogo.childImageSharp.fluid}
                    />
                  </AssociationLink>
                </AssociationWrapper>
              </DataWrapper>
            )}
            {activeProfile === 3 && (
              <DataWrapper>
                <h1>Love Sport</h1>
                <h1>Athlete Ambassador</h1>
                <hr />
                <h3>Emma Johnstone</h3>
                <FieldAndValue>
                  <Field>Name:</Field>
                  <Value>Emma Johnstone</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Position:</Field>
                  <Value>Safety Guard</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Birthplace:</Field>
                  <Value>Dublin</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>School:</Field>
                  <Value>Rosalyn Park College, Sandymount</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Club:</Field>
                  <Value>Cabra Lions Special Olympics Club</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Career High:</Field>
                  <Value>Getting selected to represent Ireland in Abu Dhabi</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Age started playing:</Field>
                  <Value>16</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Sporting Career Goal:</Field>
                  <Value>Hoping to coach young athletes in basketball</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Favourite Quote:</Field>
                  <Value>Let me win, but if I cannot win,let me be brave in the attempt.</Value>
                </FieldAndValue>
                <FieldAndValue>
                  <Field>Advice:</Field>
                  <Value>Stick to it, never give up, and you will achieve your goal.</Value>
                </FieldAndValue>
                <hr />
                <AssociationWrapper>
                  <AssociationLink href="https://www.specialolympics.ie/" target="__blank">
                    <h4>Click here for more info about Special Olympics</h4>
                  </AssociationLink>
                  <AssociationLink href="https://www.specialolympics.ie/" target="__blank">
                    <SpecialOlympicsLogo
                      style={{ position: 'absolute' }}
                      fluid={specialOlympicsLogo.childImageSharp.fluid}
                    />
                  </AssociationLink>
                </AssociationWrapper>
              </DataWrapper>
            )}
            {activeProfile === 1 && (
              <RoisinImg>
                <Img fluid={roisin.childImageSharp.fluid} />
              </RoisinImg>
            )}
            {activeProfile === 2 && (
              <FionaImg>
                <Img fluid={fiona.childImageSharp.fluid} />
              </FionaImg>
            )}
            {activeProfile === 3 && (
              <EmmaImg>
                <Img fluid={emma.childImageSharp.fluid} />
              </EmmaImg>
            )}
          </AthleteProfile>
          <Separator>
            <h4>Click To Watch Video</h4>
          </Separator>
          <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => this.handleVideoButtonClick()}>
              <ResponsiveVideo
                playing={playVideo}
                isMobile={isMobile}
                playingVimeoVideoCode={playingVimeoVideoCode}
              />
            {/* {!playVideo && <ClickToWatchImg fluid={clickToWatch.childImageSharp.fluid} />} */}
          </div>
          <Separator>
            <h4>Contact Us</h4>
          </Separator>
        </RenderWhenReady>
      </Layout>
    );
  }
}

const ClickToWatchImg = styled(Img)`
  cursor: pointer;
  max-width: 95px;
  position: absolute !important;
  width: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -32%);
  transition: filter 0.2s;

  /* :hover {
    filter: none;
  } */

  /* :not(:hover) {
    filter: grayscale(100%);
  } */

  @media screen and (min-width: 395px) {
    max-width: 120px;
  }

  ${mq.landscapeMobile`
    max-width: 159px;
  `}
`;

export const ATHLETES_QUERY = graphql`
  query {
    roisin: file(relativePath: { eq: "athletes_page/profiles/roisin.png" }) {
      childImageSharp {
        fluid(maxWidth: 428, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    fiona: file(relativePath: { eq: "athletes_page/profiles/fiona.png" }) {
      childImageSharp {
        fluid(maxWidth: 430, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    emma: file(relativePath: { eq: "athletes_page/profiles/emma.png" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    hockeyIrelandLogo: file(
      relativePath: { eq: "athletes_page/association_logos/hockey_ireland.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 130, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    irfuLogo: file(relativePath: { eq: "athletes_page/association_logos/irfu.png" }) {
      childImageSharp {
        fluid(maxWidth: 47, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    specialOlympicsLogo: file(
      relativePath: { eq: "athletes_page/association_logos/special_olympics.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 102, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    clickToWatch: file(relativePath: { eq: "home_page/click_to_watch_yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 159, maxHeight: 159, quality: 25) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const DataWrapper = styled.div`
  color: #fff;
  position: relative;
  padding: 4% 0 4% 4%;

  h1 {
    font-family: 'VTKS', sans-serif;
    font-weight: 400;
  }

  h3 {
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: -0.1125em auto 0.475em;

    ${mq.smallTablet`
      margin: -0.155em auto 0.45em;
    `}

    ${mq.largeTabletAndDesktop`
      margin: -0.155em auto 0.25em;
    `}
  }

  hr {
    max-width: calc(510px - 4%);
    margin: 16px 0;
  }

  hr:first-of-type {
    margin: 10px 0 16px;
  }

  ${mq.largeTabletAndDesktop`
    hr:first-of-type {
      margin: 7px 0 16px;
    }
  `}

  @media screen and (max-width: 959px) {
    padding: 3.25%;

    h1,
    h3 {
      text-align: center;
    }

    hr {
      margin: 16px auto;
    }

    hr:first-of-type {
      margin: 10px auto 16px;
    }
  }
`;

const FieldAndValue = styled.div`
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.25em;
  display: flex;
  flex-direction: column;
  max-width: calc(510px - 4%);
  margin: 0 auto 0.5em;

  @media screen and (min-width: 510px) {
    flex-direction: row;
    margin: 0 auto 0.25em;
  }

  ${mq.largeTabletAndDesktop`
    line-height: 1.375em;
    margin: 0 0 0.1em;
  `}
`;

const Field = styled.span`
  color: var(--custom-yellow);
  display: inline-block;
  position: relative;
  text-transform: capitalize;
  text-align: center;

  @media screen and (min-width: 510px) {
    width: 33%;
    text-align: left;
  }
`;

const Value = styled.span`
  color: #fff;
  flex-grow: 1;
  max-width: 295px;
  position: relative;
  text-align: center;
  margin: 0 auto;

  @media screen and (min-width: 510px) {
    margin-left: 0.5em;
    text-align: left;
  }

  ${mq.largeTabletAndDesktop`
    width: 19%;
  `}
`;

const NameH3 = styled.h3``;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 375px;
`;

const AthleteProfile = styled.section`
  background: #000 url(${Tile});
  position: relative;
`;

const ProfileImg = styled.div`
  bottom: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;

  @media screen and (min-width: 960px) {
    position: absolute;
    right: 0;
  }
`;

const RoisinImg = styled(ProfileImg)`
  max-width: 428px;
`;

const FionaImg = styled(ProfileImg)`
  max-width: 430px;
`;

const EmmaImg = styled(ProfileImg)`
  max-width: 460px;
`;

const AssociationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: calc(510px - 4%);
  margin-top: 1px;

  a {
    text-decoration: none;
  }

  h4 {
    cursor: pointer;
    color: #fff;
    font-weight: 400;
    text-transform: capitalize;
  }

  @media screen and (max-width: 959px) {
    margin: 3px auto 0;
  }
`;

const AssociationLink = styled.a`
  flex-grow: 1;
  max-width: 45%;
  position: relative;
`;

const AssociationLogo = styled(Img)`
  width: 100%;
  top: 0;
  right: 0;
`;

const HockeyIrelandLogo = styled(AssociationLogo)`
  max-width: 130px;
`;

const IRFULogo = styled(AssociationLogo)`
  max-width: 47px;
`;

const SpecialOlympicsLogo = styled(AssociationLogo)`
  max-width: 102px;
`;
